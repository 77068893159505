/* eslint-disable */
import { useSelector } from "react-redux";
import { Layers, Loader, Home, Users, AlignJustify, UserCheck, Calendar, Circle, Bookmark, Phone, List, Slack, Grid, PieChart, Volume2, Clock, Package, Map } from "react-feather";

const navigation = () =>
{
  const user = useSelector((state) => state.auth.user);
  const currentWorkspace = useSelector(
    (state) => state.workspaces.currentWorkspace
  );


  const tabs = [];
  const workspaceSetup = [];

  if (user && user.role == 'company') {
   
    workspaceSetup.push({
      id: "manageUsers",
      title: "Users",
      icon: <Users size={20} />,
      // navLink: "/workspace/:id/users",
      navLink: `/workspace/${currentWorkspace && currentWorkspace.id}/users`,
    });

    workspaceSetup.push({
      id: "leadlist",
      title: "Lead List",
      icon: <List size={20} />,
      navLink: "/workspace/leadlist",
    });


    workspaceSetup.push({
      id: "queue",
      title: "Queue",
      icon: <Grid size={20} />,
      navLink: "/workspace/queue",
    });

    workspaceSetup.push({
      id: "team",
      title: "Teams",
      icon: <Grid size={20} />,
      navLink: "/workspace/Team",
    });
  }

  workspaceSetup.push({
    id: "statuses",
    title: "Statuses",
    icon: <Package size={20} />,
    navLink: "/statuses",
  });

  tabs.push({
    id: "workspaceSetup",
    title: "Workspace Setup",
    icon: <Loader size={20} />,
    badge: "light-warning",
    children: workspaceSetup,
  });


  tabs.push({
    id: "dashboard",
    title: "Dashboard",
    icon: <Home size={20} />,
    navLink: "/dashboard",
  });

  tabs.push({
    id: "leadProfiles",
    title: "Lead Profiles",
    icon: <UserCheck size={20} />,
    navLink: "/leads",
  });

  tabs.push({
    id: "callflow",
    title: "CallFlow",
    icon: <Loader size={20} />,
    navLink: "/workspace/callflow",
  });

  tabs.push({
    id: "clients",
    title: "Clients",
    icon: <Users size={20} />,
    navLink: "/clients",
  });

  tabs.push({
    id: "followups",
    title: "Follow-ups",
    icon: <Calendar size={20} />,
    navLink: "/followups",
  });

  tabs.push({
    id: "workdiary",
    title: "Work diary",
    icon: <Clock size={20} />,
    navLink: "/workdiary",
  });

  //Add the tab if user role == company only
  if (user && user.role == 'company') {

    tabs.push({
      id: "call-overview",
      title: "Calls Overview",
      icon: <Phone size={20} />,
      navLink: "/call-overview",
    });

    tabs.push({
      id: "reports",
      title: "Reports",
      icon: <PieChart size={20} />,
      navLink: "/reports",
    });

    tabs.push({
      id: "campaigns",
      title: "Campaigns",
      icon: <Volume2 size={20} />,
      navLink: "/campaigns",
    });

  }


  tabs.push({
    id: "areas",
    title: "Areas",
    icon: <Map size={20} />,
    navLink: "/areas",
  });

  return tabs;

}


export default navigation;
