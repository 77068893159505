/* eslint-disable */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import toast from "react-hot-toast";
import ErrorHandler from "../utility/ErrorHandler";

axios.defaults.withCredentials = true;
const baseURL = `${process.env.REACT_APP_API_ENDPOINT}/api/areas`;

export const areasSlice = createSlice({
  name: "areas",
  initialState: {
    errors: new ErrorHandler(),
    areas: [],
    reload: false,
    loader: false,
  },
  reducers: {
    setErrors: (state, { payload }) =>
    {
      state.errors.setErrors(payload);
    },
    setAreas: (state, { payload }) =>
    {
      state.areas = payload;
    },
    setReload: (state, { payload }) =>
    {
      state.reload = payload;
    },
    setLoader: (state, { payload }) =>
    {
      state.loader = payload;
    }
  },
});

export const getAreasList = createAsyncThunk(
  "areas/index",
  async (params, { dispatch }) =>
  {
    try {
      const response = await axios.get(`${baseURL}`, { params });
      dispatch(setAreas(response.data));
    } catch (e) {
      toast.error(e.response.data.message);
    }
  }
);

export const storeOrUpdate = createAsyncThunk(
  "areas/storeOrUpdate",
  async (params, { dispatch }) =>
  {
    try {
      const response = await axios.post(`${baseURL}`, params);
      toast.success(response.data.message);
      dispatch(setReload(true));
      dispatch(setLoader(false));
      return { data: true };
    } catch (e) {
      toast.error(e.response.data.message);
      if (e.response.data?.errors) dispatch(setErrors(e.response.data.errors));
      dispatch(setLoader(false));
      return { data: false };
    }
  }
);

export const deleteArea = createAsyncThunk(
  "areas/deleteArea",
  async (params, { dispatch }) =>
  {
    try {
      const response = await axios.delete(`${baseURL}/${params.workspace_id}/${params.id}`);
      toast.success(response.data.message);
      dispatch(setReload(true));
      return { data: true };
    } catch (e) {
      toast.error(e.response.data.message);
      if (e.response.data?.errors) dispatch(setErrors(e.response.data.errors));
      return { data: false };
    }
  }
);



export const {
  setErrors,
  setAreas,
  setReload,
  setLoader,
} = areasSlice.actions;

export default areasSlice.reducer;
